<template>
  <div>
    <b-overlay
      :show="loading"
      no-wrap
    />
    <b-sidebar
      id="add-new-material-sidebar"
      :visible="isMaterialHandlerSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      width="50em"
      @change="(val) => $emit('update:is-material-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            <b>{{ materialData.name }}</b>
          </h5>

          <div>
            <b-button
              id="popover-delete-material"
              ref="button"
              variant="outline-danger"
            >
              <feather-icon
                v-show="materialData.id"
                icon="TrashIcon"
                class="cursor-pointer text-danger"
              /> {{ $t('delete') }}
            </b-button>
            <b-popover
              ref="popover"
              target="popover-delete-material"
              triggers="click"
              placement="left"
              container="popover-delete-material"
            >
              <div>
                <p>{{ $t('Are you sure?') }}</p>
                <b-button
                  size="sm"
                  variant="link"
                  class="mr-1"
                >
                  {{ $t('Cancel') }}
                </b-button>
                <b-button
                  size="sm"
                  variant="danger"
                  @click="onDelete(); hide();"
                >
                  {{ $t('Delete') }}
                </b-button>
              </div>
            </b-popover>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col cols="5">
                <!-- Name -->
                <validation-provider
                  #default="validationContext"
                  name="Name"
                  rules="required"
                >
                  <b-form-group
                    :label='$t("materials.material")'
                    label-for="name"
                  >
                    <template v-slot:label>
                      {{ $t("materials.material") }} <code>*</code>
                    </template>
                    <b-form-input
                      id="name"
                      v-model="materialData.name"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder=""
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6">
                <!-- Manufacturer -->
                <validation-provider
                  #default="validationContext"
                  :name='$t("materials.manufacturer")'
                >
                  <b-form-group
                    :label="$t('manufacturer')"
                    label-for="manufacturer"
                  >
                    <template v-slot:label>
                      {{ $t("materials.manufacturer") }}
                    </template>
                    <b-form-input
                      id="manufacturer"
                      v-model="materialData.manufacturer"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6">
                <!-- Group -->
                <validation-provider
                  #default="validationContext"
                  name="Group"
                >
                  <b-form-group
                    :label="$t('materials.group')"
                    label-for="group"
                  >
                    <b-form-input
                      id="group"
                      v-model="materialData.group"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="3">
                <!-- Price -->
                <validation-provider
                  #default="validationContext"
                  name="price"
                >
                  <b-form-group
                    :label="$t('materials.price')"
                    label-for="price"
                  >
                    <b-form-input
                      id="price"
                      v-model="materialData.price"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="0.00"
                      class="text-right"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="3">
                <!-- Stock -->
                <validation-provider
                  #default="validationContext"
                  :name="$t('materials.stock')"
                >
                  <b-form-group
                    :label="$t('materials.stock')"
                    label-for="stock"
                  >
                    <b-form-input
                      id="stock"
                      v-model="materialData.stock"
                      :state="getValidationState(validationContext)"
                      trim
                      type="number"
                      placeholder="0"
                      class="text-right"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <!-- comments -->
                <validation-provider
                  #default="validationContext"
                  name="comments"
                >
                  <b-form-group
                    :label="$t('materials.comments')"
                    label-for="comments"
                  >
                    <b-form-textarea
                      id="comments"
                      v-model="materialData.comments"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                >
                  {{ $t("Save") }}
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    @click="hide"
                >
                  {{ $t("Cancel") }}
                </b-button>
              </div>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BRow, BCol, BForm, BFormRow, BFormGroup, BFormInput, BFormDatepicker, BFormInvalidFeedback, BButton, BFormTextarea, BOverlay, BPopover,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  computed, ref, onUpdated, toRefs, watch,
} from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import VueSlider from 'vue-slider-component'
import _ from "lodash"
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import useMaterialList from '@/views/apps/material/materials-list/useMaterialsList'
import useGeneralView from "@/views/useGeneralView"

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BForm,
    BFormRow,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormInvalidFeedback,
    BPopover,
    BButton,
    BFormTextarea,
    BOverlay,
    vSelect,
    Cleave,
    VueSlider,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isMaterialHandlerSidebarActive',
    event: 'update:is-material-handler-sidebar-active',
  },
  props: {
    isMaterialHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    project_id: {
      type: Number,
      required: false,
    },
    material: {
      type: [Array, Object],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
        sliderMin: 0,
        sliderMax: 100,
        sliderDirection: 'ltr',
        sliderInterval: 1,
      },
    }
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const propMaterialId = computed(() => props.material_id)

    const blankMaterialData = {
      material_id: propMaterialId.value,
      name: '',
      group: '',
      price: '',
      quantity: '',
      comments: '',
    }

    const materialProp = toRefs(props).material

    const materialData = ref(materialProp.value ? materialProp.value : blankMaterialData)
    const materialRenewData = ref(blankMaterialData)

    const resetMaterialData = () => {
      materialData.value = materialProp.value
    }

    const { updateMaterial, deleteMaterial } = useMaterialList()
    const { fetchNotifications } = useGeneralView()

    watch(materialData, newValue => {
      materialRenewData.value = newValue
    })

    onUpdated(() => {
      resetMaterialData()
    })

    const onSubmit = async () => {
      loading.value = true
      const queryParams = _.clone(materialData.value)
      await updateMaterial(queryParams)
          .then(async response => {
            fetchNotifications()

            emit('refetch-data')
            emit('update:is-material-handler-sidebar-active', false)
          })
      loading.value = false
    }

    const onDelete = async () => {
      const queryParams = { id: materialData.value.id, project_id: materialData.value.project_id }
      await deleteMaterial(queryParams)
          .then(async response => {
            fetchNotifications()

            emit('refetch-data')
            emit('update:is-material-handler-sidebar-active', false)
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(materialData.value)

    return {
      propMaterialId,
      materialData,
      onSubmit,
      onDelete,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';

#add-new-material-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
