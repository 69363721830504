<template>
  <div>
    <material-list-edit
      :material="material"
      :is-material-handler-sidebar-active.sync="isMaterialHandlerSidebarActive"
      @refetch-data="refetchData"
    />
    <material-list-add-new
      :is-add-new-material-sidebar-active.sync="isAddNewMaterialSidebarActive"
      @refetch-data="refetchData"
    />

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search')"
              />
              <b-button
                v-if="$can('create', 'materials')"
                variant="primary"
                @click="isAddNewMaterialSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('materials.Add Material') }}</span>
              </b-button>

            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refMaterialListTable"
        class="position-relative"
        :items="fetchMaterials"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(name)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="handleMaterialClick(data.item)"
          >
            {{ data.item.name }}
          </b-link>
        </template>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalMaterials"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { ref } from '@vue/composition-api'
import useMaterialsList from './useMaterialsList'
import MaterialListAddNew from './MaterialListAddNew.vue'
import MaterialListEdit from './MaterialListEdit.vue'

export default {
  components: {
    MaterialListAddNew,
    MaterialListEdit,
    BCard,
    BRow,
    BCol,
    BLink,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BBadge,
    BPagination,
    vSelect,
  },
  setup() {
    const isAddNewMaterialSidebarActive = ref(false)
    const isMaterialHandlerSidebarActive = ref(false)
    const material = ref(null)
    const handleMaterialClick = materialData => {
      material.value = materialData
      isMaterialHandlerSidebarActive.value = true
    }

    const {
      fetchMaterials,
      tableColumns,
      perPage,
      currentPage,
      totalMaterials,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMaterialListTable,
      refetchData,
    } = useMaterialsList()

    return {
      isAddNewMaterialSidebarActive,
      fetchMaterials,
      tableColumns,
      perPage,
      currentPage,
      totalMaterials,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMaterialListTable,
      refetchData,
      isMaterialHandlerSidebarActive,
      handleMaterialClick,
      material,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.table thead th, .table tfoot th{
  text-transform: capitalize !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
