import {
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import axios from "@/libs/axios"
import i18n from '@/libs/i18n'

export default function useMaterialsList() {
  const toast = useToast()
  const refMaterialListTable = ref(null)

  const tableColumns = [
    { key: 'name', label: i18n.t('materials.material'), sortable: true },
    { key: 'group', label: i18n.t('materials.group'), sortable: true },
    { key: 'price', label: i18n.t('materials.price'), sortable: true, thClass: 'text-right', tdClass: 'text-right' },
  ]

  const perPage = ref(10)
  const totalMaterials = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refMaterialListTable.value ? refMaterialListTable.value.localItems.length : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMaterials.value,
    }
  })

  const refetchData = () => {
    refMaterialListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchMaterials = (ctx, callback) => {
    store
      .dispatch('model/material/fetchMaterials', {
        search: searchQuery.value,
        pageSize: perPage.value,
        page: currentPage.value,
        order: sortBy.value,
        dir: (isSortDirDesc.value) ? 'DESC' : 'ASC',
      })
      .then(response => {
        const { records, totalRecords } = response.data
        callback(records)
        totalMaterials.value = totalRecords
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Materials list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const updateMaterial = params => store.dispatch('model/material/updateMaterial', params)
    .then(response => response)
    .catch(error => {
      if (error.response.status === 404) {
        //
      }
    })

  const deleteMaterial = params => store.dispatch('model/material/deleteMaterial', params)
    .then(response => response)
    .catch(error => {
      if (error.response.status === 404) {
        //
      }
    })

  return {
    fetchMaterials,
    tableColumns,
    perPage,
    currentPage,
    totalMaterials,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMaterialListTable,
    refetchData,
    updateMaterial,
    deleteMaterial,
  }
}
